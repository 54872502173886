.navbar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 5px;

}

.navbar-brand {
  font-family: "Kaushan Script", cursive;
}
/*
headeer top
*/
.topbar {
  background-color: #212529;
  padding: 0;
}

.topbar .container .row {
  margin: -7px;
  padding: 0;
}

.topbar .container .row .col-md-12 {
  padding: 0;
}

.topbar p {
  margin: 0;
  display: inline-block;
  font-size: 13px;
  color: #f1f6ff;
}

.topbar p > i {
  margin-right: 5px;
}
.topbar p:last-child {
  text-align: left;
}

header .navbar {
  margin-bottom: 0;
}

.topbar li.topbar {
  display: inline;
  padding-right: 18px;
  line-height: 52px;
  transition: all 0.3s linear;
}

.topbar li.topbar:hover {
  color: #1bbde8;
}

.topbar ul.info i {
  color: #131313;
  font-style: normal;
  margin-right: 8px;
  display: inline-block;
  position: relative;
  top: 4px;
}

.topbar ul.info li {
  float: right;
  padding-left: 30px;
  color: #ffffff;
  font-size: 13px;
  line-height: 44px;
}

.topbar ul.info i span {
  color: #aaa;
  font-size: 13px;
  font-weight: 400;
  line-height: 50px;
  padding-left: 18px;
}

ul.social-network {
  border: none;
  margin: 0;
  padding: 0;
}

ul.social-network li {
  border: none;
  margin: 0;
}

ul.social-network li i {
  margin: 0;
}

ul.social-network li {
  display: inline;
  margin: 0 5px;
  border: 0px solid #2d2d2d;
  padding: 5px 0 0;
  width: 32px;
  display: inline-block;
  text-align: center;
  height: 32px;
  vertical-align: baseline;
  color: #000;
}

ul.social-network {
  list-style: none;
  margin: 5px 0 10px -25px;
  float: right;
}

.waves-effect {
  position: relative;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  vertical-align: middle;
  z-index: 1;
  will-change: opacity, transform;
  transition: 0.3s ease-out;
  color: #fff;
}
a {
  color: #0a0a0a;
  font-size: 18px;
  text-decoration: none;
}

li {
  list-style-type: none;
}
.bg-image-full {
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
}
.bg-dark {
  background-color: #222 !important;
}

.mx-background-top-linear {
  background: -webkit-linear-gradient(45deg, #f15921 48%, #1b1e21 48%);
  background: -webkit-linear-gradient(left, #f15921 48%, #1b1e21 48%);
  background: linear-gradient(45deg, #f15921 52%, #1b1e21 48%);
}
