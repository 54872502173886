
.subscribe-area {
    background-image: linear-gradient(to top, #212529 0%, #131313 100%);
    }
    
    .pb-50 {
        padding-bottom: 50px;
    }
    .pt-70 {
        padding-top: 70px;
    }
    
    .mb-15 {
        margin-bottom: 15px;
    }
    
    .subscribe-text span {
        font-size: 12px;
        font-weight: 700;
        color: #fff;
        letter-spacing: 5px;
    }
    .subscribe-text h2 {
        color: #fff;
        font-size: 36px;
        font-weight: 300;
        margin-bottom: 0;
        margin-top: 6px;
    }
    .subscribe-wrapper {
        overflow: hidden;
    }
    .mb-15 {
        margin-bottom: 15px;
    }
    .subscribe-form {
    }
    .subscribe2-wrapper .subscribe-form input {
        background: none;
        border: 2px solid #fff;
        border-radius: 0px;
        color: #fff;
        display: inline-block;
        font-size: 20px;
        font-weight: 300;
        height: 57px;
        margin-right: 17px;
        padding-left: 15px;
        margin-bottom: 15px;
        width: 70%;
        cursor: pointer;
    }
     
    .subscribe2-wrapper .subscribe-form button {
        background: #f15921;
        border: none;
        border-radius: 0px;
        color: #ffffff;
        display: inline-block;
        font-size: 20px;
        font-weight: 400;
        line-height: 1;
        padding: 18px 46px;
        transition: all 0.3s ease 0s;
    }
    .subscribe2-wrapper .subscribe-form button i {
        font-size: 1px;
        padding-left: 5px;
        color: #ffffff;
     
    }
    

a:hover{
    text-decoration:none;
}
.bottom .copyright {
    color: #e5e5e5;
    font-weight: 600;
}
.copyright a {
    color: #f15921;
    margin-left: 3px;
    padding-right: 3px;
}
.bottom p {
    margin-bottom: 0;
    line-height: 50px;
    font-size: 16px;
    font-weight: 400;
}
.copyright p span {
    color: #d1caca;
}
.bottom .copyright p, .bottom .copyright a:hover{
    color: #6c6d83;
}