hr {border-top: 1px solid #000; width:50%;}

a {color: #000;}

a:link{text-decoration:none;}

.box1{background: rgb(76, 175, 80, 0.6);}
.box2{background: rgb(192, 192, 200, 0.6);}
.box3{background: rgb(255, 0, 0, 0.6);;}



#author a{
  color: #fff;
  text-decoration: none;    
}