body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.navbar {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 5px;

}

.navbar-brand {
  font-family: "Kaushan Script", cursive;
}
/*
headeer top
*/
.topbar {
  background-color: #212529;
  padding: 0;
}

.topbar .container .row {
  margin: -7px;
  padding: 0;
}

.topbar .container .row .col-md-12 {
  padding: 0;
}

.topbar p {
  margin: 0;
  display: inline-block;
  font-size: 13px;
  color: #f1f6ff;
}

.topbar p > i {
  margin-right: 5px;
}
.topbar p:last-child {
  text-align: left;
}

header .navbar {
  margin-bottom: 0;
}

.topbar li.topbar {
  display: inline;
  padding-right: 18px;
  line-height: 52px;
  transition: all 0.3s linear;
}

.topbar li.topbar:hover {
  color: #1bbde8;
}

.topbar ul.info i {
  color: #131313;
  font-style: normal;
  margin-right: 8px;
  display: inline-block;
  position: relative;
  top: 4px;
}

.topbar ul.info li {
  float: right;
  padding-left: 30px;
  color: #ffffff;
  font-size: 13px;
  line-height: 44px;
}

.topbar ul.info i span {
  color: #aaa;
  font-size: 13px;
  font-weight: 400;
  line-height: 50px;
  padding-left: 18px;
}

ul.social-network {
  border: none;
  margin: 0;
  padding: 0;
}

ul.social-network li {
  border: none;
  margin: 0;
}

ul.social-network li i {
  margin: 0;
}

ul.social-network li {
  display: inline;
  margin: 0 5px;
  border: 0px solid #2d2d2d;
  padding: 5px 0 0;
  width: 32px;
  display: inline-block;
  text-align: center;
  height: 32px;
  vertical-align: baseline;
  color: #000;
}

ul.social-network {
  list-style: none;
  margin: 5px 0 10px -25px;
  float: right;
}

.waves-effect {
  position: relative;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  vertical-align: middle;
  z-index: 1;
  will-change: opacity, transform;
  transition: 0.3s ease-out;
  color: #fff;
}
a {
  color: #0a0a0a;
  font-size: 18px;
  text-decoration: none;
}

li {
  list-style-type: none;
}
.bg-image-full {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  -o-background-size: cover;
}
.bg-dark {
  background-color: #222 !important;
}

.mx-background-top-linear {
  background: linear-gradient(45deg, #f15921 52%, #1b1e21 48%);
}

body {
  font-family: "Charm";
  font-style: normal;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: white
}

.top_bottom_margin {
  margin-top: 85px;
  margin-bottom: -50px
}

hr {border-top: 1px solid #000; width:50%;}

a {color: #000;}

a:link{text-decoration:none;}

.box1{background: rgb(76, 175, 80, 0.6);}
.box2{background: rgb(192, 192, 200, 0.6);}
.box3{background: rgb(255, 0, 0, 0.6);;}



#author a{
  color: #fff;
  text-decoration: none;    
}
h3{
  text-align: center;
  font-size: 5vh;
  margin-bottom: 5vh;

}
.b-0 {
  bottom: 0;
}
.bg-shadow {
  background: rgba(76, 76, 76, 0);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(179, 171, 171, 0)), color-stop(49%, rgba(48, 48, 48, 0.37)), color-stop(100%, rgba(19, 19, 19, 0.8)));
  background: linear-gradient(to bottom, rgba(179, 171, 171, 0) 0%, rgba(48, 48, 48, 0.71) 49%, rgba(19, 19, 19, 0.8) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4c4c4c', endColorstr='#131313', GradientType=0 );
}
.top-indicator {
  right: 0;
  top: 1rem;
  bottom: inherit;
  left: inherit;
  margin-right: 1rem;
}
.overflow {
  position: relative;
  overflow: hidden;
}
.zoom img {
  transition: all 0.2s linear;
}
.zoom:hover img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.card{
  text-align: center;  
}
.card1{
  background-color: rgb(255,165,0);
}

.card2{
  background-color :rgb(12,164,178);
}

.subscribe-area {
    background-image: linear-gradient(to top, #212529 0%, #131313 100%);
    }
    
    .pb-50 {
        padding-bottom: 50px;
    }
    .pt-70 {
        padding-top: 70px;
    }
    
    .mb-15 {
        margin-bottom: 15px;
    }
    
    .subscribe-text span {
        font-size: 12px;
        font-weight: 700;
        color: #fff;
        letter-spacing: 5px;
    }
    .subscribe-text h2 {
        color: #fff;
        font-size: 36px;
        font-weight: 300;
        margin-bottom: 0;
        margin-top: 6px;
    }
    .subscribe-wrapper {
        overflow: hidden;
    }
    .mb-15 {
        margin-bottom: 15px;
    }
    .subscribe-form {
    }
    .subscribe2-wrapper .subscribe-form input {
        background: none;
        border: 2px solid #fff;
        border-radius: 0px;
        color: #fff;
        display: inline-block;
        font-size: 20px;
        font-weight: 300;
        height: 57px;
        margin-right: 17px;
        padding-left: 15px;
        margin-bottom: 15px;
        width: 70%;
        cursor: pointer;
    }
     
    .subscribe2-wrapper .subscribe-form button {
        background: #f15921;
        border: none;
        border-radius: 0px;
        color: #ffffff;
        display: inline-block;
        font-size: 20px;
        font-weight: 400;
        line-height: 1;
        padding: 18px 46px;
        transition: all 0.3s ease 0s;
    }
    .subscribe2-wrapper .subscribe-form button i {
        font-size: 1px;
        padding-left: 5px;
        color: #ffffff;
     
    }
    

a:hover{
    text-decoration:none;
}
.bottom .copyright {
    color: #e5e5e5;
    font-weight: 600;
}
.copyright a {
    color: #f15921;
    margin-left: 3px;
    padding-right: 3px;
}
.bottom p {
    margin-bottom: 0;
    line-height: 50px;
    font-size: 16px;
    font-weight: 400;
}
.copyright p span {
    color: #d1caca;
}
.bottom .copyright p, .bottom .copyright a:hover{
    color: #6c6d83;
}
