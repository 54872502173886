body {
  font-family: "Charm";
  font-style: normal;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: white
}

.top_bottom_margin {
  margin-top: 85px;
  margin-bottom: -50px
}
